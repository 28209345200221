import { Button, Card, Modal, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { getCardTitle } from "../../helper/helperComp";
import { IoMdAddCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { get } from "../../utilities/apis";

function Drivers() {
  const { user } = useSelector((s) => s.userreducer);
  const { Drivers } = useSelector((s) => s.driverreducer);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [drivers, setDrivers] = useState();
  const navigate = useNavigate();

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Mobile", dataIndex: "mobile", key: "mobile" },
    { title: "Availability", dataIndex: "availability", key: "availability" },
    ...(user?.role === "admin" && [
      { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    ]),
    { title: "Actions", dataIndex: "action", key: "action" },
  ];
  const getDrivers = async () => {
    let data = Drivers;
    if (search) {
      const res = await get(`/get-drivers?search=${search}`);
      data = res?.drivers;
    }

    setDrivers(
      data?.map((d) => {
        return d;
      })
    );
  };

  useEffect(() => {
    if (Drivers && !search) {
      getDrivers();
    } else if (search) {
      getDrivers();
    }
  }, [Drivers, search]);

  return (
    <>
      <Card
        title={getCardTitle(
          "Drivers",
          <IoMdAddCircle
            size={25}
            onClick={() => navigate("/drivers/add")}
            className="cursor-pointer"
          />
        )}
      >
        <Search className="my-2" onSearch={(e) => setSearch(e)} />
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            dataSource={drivers?.map((d) => {
              return {
                ...d,
                availability: (
                  <Tag color={d?.availability ? "success" : "error"}>
                    {d?.availability ? "Yes" : "No"}
                  </Tag>
                ),
                vendor: d?.vendor?.company_name,
                action: (
                  <Button
                    onClick={() => navigate(`/drivers/edit?driverId=${d?._id}`)}
                  >
                    Edit
                  </Button>
                ),
              };
            })}
          />
        </div>
      </Card>
    </>
  );
}

export default Drivers;
