import React, { useContext } from "react";
import SideNav from "../components/SideNav";
import Header from "../components/Header";
import AppContext from "../context/context";

const h = window.innerHeight;

function AppLayout({ children }) {
  const { sideBar, setSideBar } = useContext(AppContext);
  return (
    <div className="w-full  flex relative bg-slate-100" style={{ minHeight: h }}>
      <div
        className={`flex-1 max-w-[300px]  p-2 fixed h-screen top-0 left-0 z-50  lg:sticky lg:min-h-full ${
          sideBar
            ? "max-w-[270px] translate-x-0"
            : "max-w-[270px] lg:max-w-[100px] -translate-x-[100%] lg:translate-x-0"
        } transition-all`}
        style={{ maxHeight: h }}
      >
        <SideNav />
      </div>
      <div className="flex-1 h-full  relative flex flex-col gap-4">
        <Header />
        <div className="flex-1 p-2">{children}</div>
      </div>
    </div>
  );
}

export default AppLayout;
