import React, { useEffect, useState } from 'react'
import AppContext from './context'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../redux/actions/user.actions'
import { LoadApp } from '../redux/actions/RootActions'

function States(props) {
  const {user}=useSelector(s=>s.userreducer)
    const [sideBar,setSideBar]=useState(false)
    const dispatch = useDispatch()


    useEffect(()=>{
      dispatch(getUser())


    },[])
    useEffect(()=>{
      if(user){
        LoadApp()
      }

    },[user])
    
  return (
 <AppContext.Provider value={{sideBar,setSideBar}}>
{props.children}
 </AppContext.Provider>
  )
}

export default States