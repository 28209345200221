import {
    Button,
    Card,
    Form,
    Input,
    Select,
    Spin,
    Switch,
    Typography,
    message,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useNavigate, useParams, useSearchParams } from "react-router-dom";
  import { getCardTitle } from "../../helper/helperComp";
  import { useForm } from "antd/es/form/Form";
  import { get, post, put } from "../../utilities/apis";
  import { useDispatch, useSelector } from "react-redux";
import { getCarsModal } from "../../redux/actions/cars.action";
  
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  
  function CarModalEditor() {
    const { user, Vendors } = useSelector((s) => s.userreducer);
    const { CarsModals } = useSelector((s) => s.carsreducer);
    const { action } = useParams();
    const [searchParams,setSearchParams]=useSearchParams()
    const mId = searchParams.get("mId")
  
    const [form] = useForm();
    const value = (key) => form.getFieldValue(key);
    const setValue = (key, val) => form.setFieldValue(key, val);
  
    const [loading, setLoading] = useState();
    const [data,setData]=useState()
    const [changingValues, setChangingValues] = useState();
    const dispatch = useDispatch()
    const navigate = useNavigate()
  
    const onSubmit = async (vals) => {
      const reqBody = vals;
      setLoading(true);
          if(action==="edit"){
            const up = await put(`/update-default-car-modal/${mId}`,reqBody)
            if(up?.success){
              message.success(up?.message)
            }
            setLoading(false);
            dispatch(getCarsModal())
            navigate(-1)
            return
          }
      //   const res = await post("/create-default-car-modal", reqBody);
      //   if (res?.success) {
      //     form.resetFields();
      //     setLoading(false);
      //   }
    
  
      const res = await post("/create-default-car-modal", reqBody);
      if (res?.success) {
        form.resetFields();
      }
      setLoading(false);
      dispatch(getCarsModal())
      navigate(-1)
      return;
    };

    const getData = async()=>{
      const res = await get(`/get-cars-modals?modalId=${mId}`)
      if(res?.success){
        setData(res?.carModal)
        form.setFieldsValue(res?.carModal)
      }
    }


    useEffect(()=>{
      if(action==="edit"&&mId){
        getData()
      }

    },[mId,action])

  
    useEffect(() => {
      if (changingValues && CarsModals) {
        if (value("modal") || "modal" in changingValues) {
          const md = CarsModals?.find(
            (cm) => cm?._id === value("modal") || changingValues?.modal
          );
  
          form.setFieldsValue({
            ...md,
            rate: md?.vendor_price,
            
          });
        }
      }
    }, [changingValues, CarsModals]);


  
    return (
      <Card title={getCardTitle(`New Car`)}>
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          onValuesChange={(e) => setChangingValues(e)}
          initialValues={{active:true}}
        >

  
    
  
          <Form.Item label="Name" name={"name"}>
            <Input />
          </Form.Item>
          <Form.Item label="Company Name" name={"company"}>
            <Input />
          </Form.Item>
          <div className="grid grid-cols-3 gap-2">
            <Form.Item label="Market Price" name={"market_price"}>
              <Input />
            </Form.Item>
            <Form.Item label="Our Price" name={"our_price"}>
              <Input />
            </Form.Item>
            <Form.Item label="Vendor Price" name={"vendor_price"}>
              <Input />
            </Form.Item>
          </div>
  
          <Form.Item label="Seating Capcity" name={"seating_cap"}>
            <Input />
          </Form.Item>
          <Form.Item label="Thumbnail" name={"thumbnail"}>
            <Input />
          </Form.Item>
  
          <div className="grid grid-cols-3 gap-2">
            <Form.Item label="Night Charge" name={"night_charge"}>
              <Input />
            </Form.Item>
            <Form.Item label="Min Day Charge" name={"min_charge"}>
              <Input />
            </Form.Item>
            <Form.Item label="Min Km" name={"min_km"}>
              <Input />
            </Form.Item>
          </div>
  
          <div className="grid grid-cols-3 gap-2">
            <Form.Item label="Type" name={"type"}>
              <Input />
            </Form.Item>
            <Form.Item label="category" name={"category"}>
              <Input />
            </Form.Item>
            <Form.Item label="Fuel Type" name={"fuel_type"}>
              <Select
                options={["deisal", "petrol", "cng"]?.map((f) => {
                  return {
                    label: (
                      <Typography.Text className="capitalize">
                        {f}
                      </Typography.Text>
                    ),
                    value: f,
                  };
                })}
              />
            </Form.Item>
          </div>

        <Form.Item label="Active" name={"active"}>
          <Switch />
        </Form.Item>
  
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Card>
    );
  }
  
  export default CarModalEditor;
  