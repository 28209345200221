import STORE from "../Store"
import { getCarsModal } from "./cars.action"
import { getDashboardData } from "./dashboard.action"
import { GetDrivers } from "./drivers.action"
import { getVendors } from "./user.actions"


export const LoadApp=()=>{
    STORE.dispatch(getCarsModal())
    STORE.dispatch(getVendors())
    STORE.dispatch(GetDrivers())
    STORE.dispatch(getDashboardData())
}