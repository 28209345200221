import { Button, Card, Image, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCardTitle } from "../../helper/helperComp";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { get } from "../../utilities/apis";
import Search from "antd/es/input/Search";
import { CiEdit } from "react-icons/ci";

function Cars() {
  const { user } = useSelector((s) => s.userreducer);
  const { CarsModals } = useSelector((s) => s.carsreducer);
  const [searchParams,setSearchParams]=useSearchParams()
  const tab = searchParams.get("tab")
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    if(tab){
      setModal(tab)
    }else{
      setModal(null)
    }

  },[tab])


  const onTabChange=(t)=>{
    navigate(`/cars?tab=${t}`)

  }

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Number", dataIndex: "car_number", key: "car_number" },
    { title: "Availability", dataIndex: "availability", key: "availability" },
    ...(user?.role === "admin" && [
      { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    ]),
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const getData = async () => {
    setLoading(true);
    let res;
    if (search) {
      res = await get(`/get-cars?search=${search}`);
    } else if (modal) {
      res = await get(`/get-cars?modal_id=${modal}`);
    }
    if (res?.success) {
      setData(
        res?.cars?.map((c) => {
          return {
            ...c,
            availability: (
              <Tag color={c?.availability ? "success" : "error"}>
                {c?.availability ? "Yes" : "No"}
              </Tag>
            ),
            vendor: c?.vendor?.company_name,
            name: c?.modal?.name,
            action: (
              <Button onClick={() => navigate(`/cars/edit?carId=${c?._id}`)}>
                Edit
              </Button>
            ),
          };
        })
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (CarsModals && !modal&&!tab) {
      setModal(CarsModals?.at(0)?._id);
    }
    if (modal && modal !== "add") {
      getData();
    } else if (modal && modal === "add") {
      navigate(`/cars/modal/create`);
    }
  }, [modal, CarsModals, search]);

  const tabList = CarsModals && [
    {
      label: (
        <IoMdAddCircle
          size={25}
          onClick={() => navigate(`/cars/modal/create`)}
        />
      ),
      key: "add",
    },
    ...CarsModals?.map((c) => {
      return {
        label: (
          <div className="flex items-center gap-2 relative">
            <img src={c?.thumbnail} className="w-[40px] rounded shadow" />
            {c?.name}
            {modal === c?._id && (
              <span
                className=" top-0 -right-2 bg-white shadow rounded-sm"
                onClick={() => navigate(`/cars/modal/edit?mId=${c?._id}`)}
              >
                <CiEdit size={20} />
                
              </span>
            )}
            <span className={`p-1 rounded-full ${c?.active?"bg-green-500":"bg-red-500"} top-0 left-0 absolute`}></span>
          </div>
        ),
        key: c?._id,
      };
    }),
  ];


  return (
    <Card
      tabList={user?.role === "admin" && !search && tabList}
      title={getCardTitle(
        "Cars",
        <Link to={`/cars/add`}>
          <IoMdAddCircle size={25} />
        </Link>
      )}
      onTabChange={onTabChange}
      activeTabKey={modal}
    >
      {modal !== "add" ? (
        <>
          <Search className="my-2" onSearch={(e) => setSearch(e)} />
          <div className="overflow-x-auto">
            <Table columns={columns} dataSource={data} loading={loading} />
          </div>
        </>
      ) : null}
    </Card>
  );
}

export default Cars;
