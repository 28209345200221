import { get } from "../../utilities/apis"
import { GET_DASH_DATA } from "../types/dashboard.types"



export const getDashboardData = ()=>{
    return async(dispatch)=>{
        const res = await get("/dashboard")
        if(res?.success){
            dispatch({
                type:GET_DASH_DATA,
                payload:res?.data
            })
        }
    }
}