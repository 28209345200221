import React, { useEffect } from "react";
import DataCard from "./cmpnts/DataCard";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "antd";
import { MyChart, PieChart } from "./cmpnts/MyChart";
import Bookings from "../bookings/Bookings";
import dayjs from "dayjs";
import { DefaultDateFormat } from "../../helper/constants";
import { getDashboardData } from "../../redux/actions/dashboard.action";

function Dashboard() {
  const { data } = useSelector((s) => s.dashboardReducer);
const dispatch = useDispatch()

  useEffect(()=>{
dispatch(getDashboardData())
  },[])

  if (!data) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Skeleton active />

        <Skeleton active />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Skeleton active />

          <Skeleton active />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Skeleton active />

          <Skeleton active />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Skeleton active />

          <Skeleton active />
        </div>
      </div>
    );
  }

  return (
    <>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div className="flex-1  flex flex-col gap-4">
        <div className="grid grid-cols-3 gap-2 h-full">
          <DataCard label={"TodayPickup"} data={data?.bookings?.todayPickup} />
          <DataCard label={"Today Booking New"} data={data?.bookings?.today} />
          <DataCard label={"Total Users"} data={data?.user?.total} />
          <DataCard label={"Total Vendors"} data={data?.vendor?.total} />
          <DataCard label={"Modals"} data={data?.cars?.modal} />
          <DataCard label={"Total Cars"} data={data?.cars?.cars} />
  
        </div>
        <div className="w-[300px]">
          {/* <PieChart data={data?.bookings?.bookings}/> */}
        </div>
      </div>
      <div className="h-[350px] bg-white p-4 shadow rounded-lg ">
        <MyChart data={data?.bookings?.last7} />
      </div>
      <div className="h-[350px] bg-white p-4 shadow rounded-lg ">
        <PieChart data={data?.bookings?.bookings} />
      </div>
<Bookings search={dayjs().format(DefaultDateFormat)} title={"Today Pickup's"} className="h-[350px] overflow-auto"/>
    </div>
    </>
  );
}
export default Dashboard;
