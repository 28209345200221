import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import States from './context/States';
import { Provider } from 'react-redux';
import STORE from './redux/Store';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
<ConfigProvider theme={{
  token:{
    colorPrimary:"#F29F67"
  }
}}>


  <Provider store={STORE}>


  <States>
    <App />
  </States>
  </Provider>
  </ConfigProvider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
