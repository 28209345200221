import Chart from "chart.js/auto";
import React, { useEffect, useRef } from "react";
import { getRandomColor } from "../../../helper/helper";

export const MyChart = ({ data, type }) => {
  const chartRef = useRef(null);

  // const data = [
  //   { year: 2010, count: 10 },
  //   { year: 2011, count: 20 },
  //   { year: 2012, count: 15 },
  //   { year: 2013, count: 25 },
  //   { year: 2014, count: 22 },
  //   { year: 2015, count: 30 },
  //   { year: 2016, count: 28 },
  // ];

  useEffect(() => {
    const doc = document.getElementById("my-chart");
    if (doc) {
      const ctx = doc.getContext("2d");

      new Chart(ctx, {
        type: type || "line",
        data: {
          labels: Object.keys(data)
            .reverse()
            .map((key) => key),
          datasets: [
            {
              label: "Last 7 days created bookings",
              data: Object.entries(data)
                .reverse()
                .map(([key, value]) => value),
              pointStyle: "circle",
              // fill: false,
              borderColor: "#F29F67",
              pointBackgroundColor: "#F29F67",
              pointBorderColor: "#F29F67",
              pointRadius: 10,
              pointHoverRadius: 15,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, []);

  return <canvas id="my-chart" />;
};

export const PieChart = ({ data, type }) => {
  // const data = [
  //   { year: 2010, count: 10 },
  //   { year: 2011, count: 20 },
  //   { year: 2012, count: 15 },
  //   { year: 2013, count: 25 },
  //   { year: 2014, count: 22 },
  //   { year: 2015, count: 30 },
  //   { year: 2016, count: 28 },
  // ];

  useEffect(() => {
    const doc = document.getElementById("pie-chart");
    if (doc) {
      const ctx = doc.getContext("2d");

      new Chart(ctx, {
        type: "pie",
        data: {
          labels: Object.keys(data)
            .reverse()
            .map((key) => key),
          datasets: [
            {
              label: "Last 7 days bookings",
              data: Object.entries(data)
                .reverse()
                .map(([key, value]) => value),
              pointStyle: "circle",
              backgroundColor: Object.entries(data)?.map((key) => {
                return getRandomColor();
              }),
              // fill: false,
              borderColor: "#F29F67",
              pointBackgroundColor: "#F29F67",
              pointBorderColor: "#F29F67",
              pointRadius: 10,
              pointHoverRadius: 15,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Bookings'
            }
          }
        },
      });
    }
  }, []);

  return <canvas id="pie-chart" />;
};
