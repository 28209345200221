
import { GETUSER, GET_VENDORS, USER_LOADING } from "../types/user.types"


export const userreducer=(state={AppLoading:true},action)=>{
    const {type,payload}=action


    switch (type) {
        case GETUSER:
           return {...state,user:payload}
        case USER_LOADING:
           return {...state,AppLoading:payload}
    
           case GET_VENDORS:
            return {...state,Vendors:payload}
        default:
          return state
    }

}