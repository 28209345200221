import { Button, Card, Table, Tabs, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { getCardTitle } from "../../helper/helperComp";
import { BookingPaymentStatus, BookingStatus } from "../../helper/constants";
import { get } from "../../utilities/apis";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import Search from "antd/es/input/Search";


const tabList = Object.keys(BookingStatus)?.map((bs) => {
  return {
    label: <Typography.Text className="lowercase">{bs}</Typography.Text>,
    key: BookingStatus[bs],
  };
});

function Bookings(props) {
  const { user } = useSelector((s) => s.userreducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId") || props.user;
  const vendorId = searchParams.get("vendorId");
  const [status, setStatus] = useState(BookingStatus.PROCESSING);
  const [paymentStatus, setPaymentStatus] = useState(
    BookingPaymentStatus.PENDING
  );
  const [search, setSearch] = useState(props?.search||"");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Pickup", dataIndex: "pick_up", key: "pick_up" },
    { title: "To", dataIndex: "drop_off", key: "drop_off" },
    { title: "Date", dataIndex: "pick_up_date", key: "pick_up_date" },
    ...(user?.role === "admin"
      ? [{ title: "Vendor", dataIndex: "vendor", key: "vendor" }]
      : []),
    ...(userId
      ? [
          {
            title: "Payment Status",
            dataIndex: "user_payment_status",
            key: "user_payment_status",
          },
        ]
      : []),
    ...(userId
      ? [
          {
            title: "Total Amount",
            dataIndex: "final_amount",
            key: "final_amount",
          },
        ]
      : []),
    ...(userId
      ? [
          {
            title: "Paid Amount",
            dataIndex: "total_paid_amount",
            key: "total_paid_amount",
          },
        ]
      : []),
    ...(userId
      ? [
          {
            title: "Pending Amount",
            dataIndex: "pendingPayment",
            key: "pendingPayment",
          },
        ]
      : []),
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const getData = async () => {
    setLoading(true);

    const query =search?{ search}: {
      bookingStatus: status,
      ...(userId ? { userId } : {}),
      ...(vendorId ? { vendorId } : {}),

      p: page,
      l: limit,
      ...(status===BookingStatus.COMPLETED? {paymentStatus: paymentStatus}:{}),
    };

    const res = await get(`/get-bookings`, query);

    if (res?.success) {
      setTotal(res?.count);
      setData(
        res?.bookings?.map((b) => {
          const pp = Number(b?.final_amount) - Number(b?.total_paid_amount);
          return {
            ...b,
            vendor: b?.vendor?.company_name,
            pendingPayment: isNaN(pp) ? "N/A" : pp,
            action: (
              <div className="flex gap-2 flex-col">
                <Button
                  onClick={() =>
                    navigate(`/bookings/booking/edit?bookingId=${b?._id}`)
                  }
                >
                  View
                </Button>
                <Button
                  onClick={() => {
                    if (userId) {
                      navigate(
                        `/bookings/booking/new?bookingId=${b?._id}&userId=${userId}`
                      );
                      return;
                    }
                    navigate(`/bookings/booking/new?bookingId=${b?._id}`);
                  }}
                >
                  Copy
                </Button>
              </div>
            ),
          };
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {

    getData();
  }, [status, page, limit, paymentStatus,search]);



  return (
    <div {...props}>
      <Card
        title={getCardTitle(
          props?.title||"Bookings",
          <IoMdAddCircle
            size={25}
            onClick={() =>
              navigate(
                userId
                  ? `/bookings/booking/new?userId=${userId}`
                  : "/bookings/booking/new"
              )
            }
          />
        )}
        tabList={search?[]:tabList}
        activeTabKey={status}
        onTabChange={(t) => setStatus(t)}
      >
              <Search onSearch={(e)=>setSearch(e)}  placeholder="Search Bookings..." />
        <div className="overflow-x-auto mt-2">
          {status === BookingStatus.COMPLETED&&!search && (
            <Tabs
              items={Object.entries(BookingPaymentStatus)?.map(
                ([key, value]) => {
                  return { label: key, key: value };
                }
              )}
              activeKey={paymentStatus}
              onChange={(t) => setPaymentStatus(t)}
            />
          )}
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={{
              pageSize: limit,
              onChange: (e) => setPage(e),
              total: total,
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Bookings;
