import { Button, Card, Form, Input, Select, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCardTitle } from "../../helper/helperComp";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { get, post, put } from "../../utilities/apis";
import STORE from "../../redux/Store";
import { GetDrivers } from "../../redux/actions/drivers.action";

function DriverEditor() {
  const { user, Vendors } = useSelector((s) => s.userreducer);
  const { action } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const driverId = searchParams.get("driverId");
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState();

  const onFinish = async (values) => {
    //   return console.log(values);

    if (user?.role !== "admin") {
      values.vendor = user?._id;
    }

    if (!values.photo) {
      values.photo =
        "https://i.pinimg.com/originals/ff/a0/9a/ffa09aec412db3f54deadf1b3781de2a.png";
    }

    setLoading(true);
    if (action === "edit") {
      const res = await put(`/update-driver/${driverId}`, values);
      if (res?.success) {
        form.resetFields();
        message.success(res?.message);
        STORE.dispatch(GetDrivers());
        navigate("/drivers");
      } else {
        message.error(res?.message);
      }
      setLoading(false);
      return;
    } else {
      const res = await post("/create-driver", values);
      if (res?.success) {
        form.resetFields();
        message.success("Driver Created Successfully!");
        STORE.dispatch(GetDrivers());
        navigate("/drivers");
      } else {
        message.error(res?.message);
      }
      setLoading(false);
    }
  };

  const getData = async () => {
    setFetching(true);
    const res =await get(`/get-drivers?driverId=${driverId}`);
    if (res?.success) {
      setData(data);
      form.setFieldsValue({
        ...res?.driver,
        vendor:res?.driver?.vendor?._id
      });
    } else {
      message.error(res?.message);
    }

    setFetching(false);
  };
  useEffect(() => {
    if (action === "edit" && driverId) {
      getData();
    }
  }, [action, driverId]);
  return (
    <Card title={getCardTitle(`Driver ${action}`)} loading={fetching}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ availability: true }}
      >
        {user?.role === "admin" && (
          <Form.Item
            label="Vendor"
            name={"vendor"}
            rules={[{ required: true }]}
          >
            <Select
              options={Vendors?.map((v) => {
                return { label: v?.company_name, value: v?._id };
              })}
            />
          </Form.Item>
        )}
        <Form.Item label="Name" name={"name"} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Mobile" name={"mobile"} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Profile" name={"photo"}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name={"email"}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name={"password"}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Availability" name={"availability"}>
          <Switch />
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Card>
  );
}

export default DriverEditor;
