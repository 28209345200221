

import { get } from "../../utilities/apis"
import { GETUSER, GET_VENDORS, USER_LOADING } from "../types/user.types"




export const getUser=()=>{
    return async(dispatch)=>{
        const res = await get("/auth/getuser")
        if(res?.success){
            dispatch({
                type:GETUSER,
                payload:res?.user
            })
        }

        dispatch({
            type:USER_LOADING,
            payload:false
        })
    }
}

export const getVendors=()=>{
    return async(dispatch)=>{
        const res = await get("/get-vendors")
  
            dispatch({
                type:GET_VENDORS,
                payload:res?.vendors
            })
        
        
    }
}