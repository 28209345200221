import { message } from "antd"
import axios from "axios"


// const serverUrl = "http://192.168.21.109:8000/api/admin"
const serverUrl = "https://api.safarwaala.com/api/admin"
// const serverUrl = "http://localhost:8000/api/admin"
const token = localStorage.getItem("JWT_TOKEN_ADMIN")

export const post=async(endpoint,data)=>{
    let url = serverUrl+endpoint
    const res = await axios.post(url,data,{
        withCredentials:true,
        headers:{
            "Content-Type":"application/json",
            ...(token&&{token:token})
        }
    })
   

        return res?.data
    
message.error(res?.data?.message)
}
export const put=async(endpoint,data)=>{
    let url = serverUrl+endpoint
    const res = await axios.put(url,data,{
        withCredentials:true,
        headers:{
            "Content-Type":"application/json",
            ...(token&&{token:token})
        }
    })

        return res?.data
    
message.error(res?.data?.message)
}

export const get=async(endpoint,params)=>{
    let url = serverUrl+endpoint
    const res = await axios.get(url,{
        withCredentials:true,
        headers:{
            "Content-Type":"application/json",
            ...(token&&{token:token})
        },
        ...(params?{params}:{})

    })


        return res?.data
    
message.error(res?.data?.message)

}