
import { GET_CARS_MODALS } from "../types/cars.types";




export const carsreducer=(state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case GET_CARS_MODALS:
    return {...state,CarsModals:payload}
        default:
return state
    }
}