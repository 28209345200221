import React, { useEffect, useState } from "react";
import { get } from "../../utilities/apis";
import { Button, Card, Table, Tabs, Tag, message } from "antd";
import { getCardTitle } from "../../helper/helperComp";
import Search from "antd/es/input/Search";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom";

function Users() {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("user");

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Number", dataIndex: "mobile", key: "mobile" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Verified", dataIndex: "verified", key: "verified" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const getData = async () => {
    setLoading(true);
    let res;
    if (search) {
      res = await get(`/users?search=${search}`);
    } else {
      const query ={
        p:page,
        l:limit,
        ...(role&&role!=="all"?{role:role}:{})
      }
      res = await get(`/users`,query);
    }
    if (res?.success) {
      setData(res?.users?.map((u)=>{
        return {
          ...u,
          verified:<Tag color={u?.verified?"success":"error"}>{u?.verified?"Yes":"No"}</Tag>,
          action:<div className="flex items-center gap-2"><Link to={`/users/edit?userId=${u._id}`}><Button>Edit</Button></Link><Link to={`/users/view/${u._id}`}><Button type="primary">View</Button></Link></div>
    }}));
      setCount(res?.count);
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [page, search,role]);

  return (
    <Card  title={getCardTitle("Users",<Link to={"/users/add"}><IoMdAdd size={25}/></Link>)} tabList={search?[]:[{label:"User",key:"user"},{label:"Company",key:"company"}]} onTabChange={(r)=>setRole(r)}>
      <Search onSearch={(e) => setSearch(e)} placeholder="Search user..." />
  
      <Table
        dataSource={data}
        columns={columns}
        pagination={
          !search && {
            onChange: (e) => setPage(e),
            pageSize: limit,
            total: count,
            current: page,
          }
        }
        className="mt-2"
        loading={loading}
      />
    </Card>
  );
}

export default Users;
