import { Button, Card, Form, Input, Select, Switch, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { get, post } from "../../utilities/apis";
import { vaildObjData } from "../../helper/helper";

function UserEditor() {
  const [form] = useForm();
  const { action } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const navigate = useNavigate();

  const onFinish = async (vals) => {
    vals = vaildObjData(vals);
    let res;
    setLoading(true);
    if (action === "add") {
      res = await post(`/users/create`, vals);
    }
    if (action === "edit") {
      res = await post(`/users/update/${userId}`, vals);
    }
    if (res?.success) {
      message.success(res?.message);
      navigate("/users");
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };

  const getData = async () => {
    setFetching(true);
    const res = await get(`/users?userId=${userId}`);
    if (res?.success) {
      form.setFieldsValue(res?.user);
      setFetching(false);
    } else {
      message.error(res?.message);
    }
  };
  useEffect(() => {
    if (action === "edit" && userId) {
      getData();
    }
  }, [action, userId]);

  return (
    <Card title={`User ${action}`} loading={fetching}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ verified: true }}
      >
        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>
        <Form.Item label="Mobile" name={"mobile"}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name={"email"}>
          <Input />
        </Form.Item>
        {/* <Form.Item label="Password" name={"password"}>
          <Input />
        </Form.Item> */}

        <Form.Item label="Role" name={"role"}>
          <Select
            options={[
              { label: "User", value: "user" },
              { label: "Company", value: "company" },
            ]}
          />
        </Form.Item>
        <Form.Item label="Verified" name={"verified"}>
          <Switch />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Card>
  );
}

export default UserEditor;
