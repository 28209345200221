export const DefaultDateFormat = "DD-MM-YYYY";
export const BookingStatus = {
  PROCESSING: "PROCESSING",
  CONFIRMED: "CONFIRMED",
  ONGOING: "ONGOING",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const BookingPaymentStatus = {
  PENDING: "PENDING",
  PAID: "PAID",
  BILLED: "BILLED",
};

export const BookingTypes = {
  OUTSTATION: "OUTSTATION",
  DAILY: "DAILY",
};

export const BILL_TYPES = {
  UNBILLED: "UNBILLED",
};
export const BILLSTATUS = {
  PENDING: "PENDING",
  PAID: "PAID",
};
