import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get, post } from "../../utilities/apis";
import { Button, Card, Typography, message } from "antd";
import Bookings from "../bookings/Bookings";
import { BILL_TYPES } from "../../helper/constants";
import { getCardTitle } from "../../helper/helperComp";
import Bills from "../bills/Bills";

function UserOverview() {
  const { userId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [user, setUser] = useState();
  const [metrics, setMetrics] = useState();

  const getData = async () => {
    setFetching(true);
    const res = await get(`/users?userId=${userId}`);
    if (res?.success) {
      setUser(res?.user);
      setMetrics(res?.metrics);
      setFetching(false);
    } else {
      message.error(res?.message);
    }
  };

const getUnbilledAmount = async()=>{
  const res = await post("/bills/generate",{user:userId,type:BILL_TYPES.UNBILLED})
  if(res?.success){
getData()
  }else{
    message.error(res?.message)
  }
}


  useEffect(() => {
    getData();
  }, [userId]);
  return <div className="flex flex-col gap-2">
  <Card title={getCardTitle(user?.name,<Button onClick={getUnbilledAmount}>Get Unbilled Bill</Button>)}>

    {metrics&&Object.entries(metrics).map(([key,value])=>{
      return(

    <div className="flex items-center justify-between">
      <Typography>{key}</Typography>
      <Typography>{value}</Typography>
    </div>
      )
    })}

  </Card>
  <Bookings user={userId}/>
  <Bills user={userId}/>
  </div>;
}

export default UserOverview;
