import { Typography } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'

function Header() {
    const{pathname}=useLocation()
const parent = pathname?.split("/")?.at(1)
  return (
    <div className='w-full bg-slate-50  backdrop-blur-sm p-4  sticky top-2 left-0 rounded-lg shadow z-10'>
        <div className='w-full h-full flex items-center justify-between'>
            <Typography.Text type='secondary'>Dashboard/{parent}
            </Typography.Text>


        </div>

    </div>
  )
}

export default Header