import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCardTitle } from "../../helper/helperComp";
import { useForm } from "antd/es/form/Form";
import { get, post, put } from "../../utilities/apis";
import { useSelector } from "react-redux";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function CarEditor() {
  const { user, Vendors } = useSelector((s) => s.userreducer);
  const { CarsModals } = useSelector((s) => s.carsreducer);
  const { action } = useParams();
  const [searchParams,setSearchParams]=useSearchParams()
  const carId = searchParams.get("carId")

  const [form] = useForm();
  const value = (key) => form.getFieldValue(key);
  const setValue = (key, val) => form.setFieldValue(key, val);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [changingValues, setChangingValues] = useState();
  const [car,setCar]=useState()
  const navigate = useNavigate();

  const onSubmit = async (vals) => {
    const reqBody = vals;
    setLoading(true);
    // if (type === "modal") {
    //   //   if(type==="edit"){
    //   //     const up = await put(`/update-default-car-modal/${carId}`,reqBody)
    //   //     if(up?.success){
    //   //       message.success(up?.message)
    //   //     }
    //   //     setLoading(false);
    //   //     return
    //   //   }
    //   const res = await post("/create-default-car-modal", reqBody);
    //   if (res?.success) {
    //     form.resetFields();
    //     setLoading(false);
    //   }
    //   return;
    // }
          if(action==="edit"){
          const up = await put(`/update-car/${carId}`,reqBody)
          if(up?.success){
            message.success(up?.message)
          }
          setLoading(false);
          navigate(-1)
          return
        }

    const res = await post("/create-car", reqBody);
    if (res?.success) {
      form.resetFields();
    }
    setLoading(false);
    navigate(-1)
    return;
  };


  const getData = async()=>{
    setFetching(true)
    const res = await get(`/get-cars?carId=${carId}`)
    if(res?.success){
        form.setFieldsValue(res?.car)
        setCar(res?.car)
    }
    setFetching(false)
  }

  useEffect(()=>{
    if(action==="edit"&&carId){
        getData()
        
    }
  },[action,carId])
  useEffect(() => {
  
    if (changingValues && CarsModals) {
      if (value("modal") || "modal" in changingValues) {
        const md = CarsModals?.find(
          (cm) => cm?._id ===   changingValues?.modal
        );


        form.setFieldsValue({
          ...md,
        });
      }
    }

  }, [changingValues, CarsModals]);

  useEffect(() => {
    if(car&&action==="edit"&&!fetching&&CarsModals){
        if (value("modal") || "modal" in car) {
            const md = CarsModals?.find(
              (cm) => cm?._id === car?.modal
            );
    console.log(md);
            form.setFieldsValue({
              ...md,
            
            });
          }
     }

  }, [fetching, CarsModals,car]);


  return (
    <Card title={getCardTitle(`New Car`)}>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        onValuesChange={(e) => setChangingValues(e)}
      >
        <Form.Item label="Car Modal" name={"modal"}>
          <Select
            options={CarsModals?.map((c) => {
              return {
                label: c?.name + `(${c?.fuel_type})`,
                value: c?._id,
              };
            })}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>

        <Form.Item label="Car Number" name={"car_number"}>
          <Input />
        </Form.Item>
        {user?.role === "admin" && Vendors && (
          <Form.Item name={"vendor"} label="Vendor">
            <Select
              options={Vendors?.map((v) => {
                return {
                  label: v?.company_name,
                  value: v?._id,
                };
              })}
              filterOption={filterOption}
              showSearch
            />
          </Form.Item>
        )}

        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>
        <Form.Item label="Company Name" name={"company"}>
          <Input />
        </Form.Item>
        <div className="grid grid-cols-3 gap-2">
          <Form.Item label="Market Price" name={"market_price"}>
            <Input />
          </Form.Item>
          <Form.Item label="Our Price" name={"our_price"}>
            <Input />
          </Form.Item>
          <Form.Item label="Vendor Price" name={"vendor_price"}>
            <Input />
          </Form.Item>
        </div>

        <Form.Item label="Seating Capcity" name={"seating_cap"}>
          <Input />
        </Form.Item>
        <Form.Item label="Thumbnail" name={"thumbnail"}>
          <Input />
        </Form.Item>

        <div className="grid grid-cols-3 gap-2">
          <Form.Item label="Night Charge" name={"night_charge"}>
            <Input />
          </Form.Item>
          <Form.Item label="Min Day Charge" name={"min_charge"}>
            <Input />
          </Form.Item>
          <Form.Item label="Min Km" name={"min_km"}>
            <Input />
          </Form.Item>
        </div>

        <div className="grid grid-cols-3 gap-2">
          <Form.Item label="Type" name={"type"}>
            <Input />
          </Form.Item>
          <Form.Item label="category" name={"category"}>
            <Input />
          </Form.Item>
          <Form.Item label="Fuel Type" name={"fuel_type"}>
            <Select
              options={["deisal", "petrol", "cng"]?.map((f) => {
                return {
                  label: (
                    <Typography.Text className="capitalize">
                      {f}
                    </Typography.Text>
                  ),
                  value: f,
                };
              })}
            />
          </Form.Item>
        </div>

        <Form.Item label="Availability" name={"availability"}>
          <Switch />
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Card>
  );
}

export default CarEditor;
