import { GET_DASH_DATA } from "../types/dashboard.types"



export const dashboardReducer= (state={},action)=>{
const {type,payload}= action

switch (type) {
    case GET_DASH_DATA:
        return {...state,data:payload}
        
   

    default:
   return state
}


}