"use client"

import { FETCHING, GET_BOOKINGS } from "../types/booking.types";



 const bookingreducer = (state={},action)=>{
    const {type,payload}=action

    switch (type) {
        case GET_BOOKINGS:
            return {...state,Bookings:payload}
            
            case FETCHING:
                return {...state,Fetching:payload}
    
        default:
            return state
    }
}


export default bookingreducer