import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { AUTH_ROUTES, ROUTES } from "./Routes";
import AppLayout from "./layout/AppLayout";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";

function App() {
  const { user, AppLoading } = useSelector((s) => s.userreducer);

  if (AppLoading) {
    return (
      <div className="w-full h-screen">
        <Loader />
      </div>
    );
  }

  if (!AppLoading && !user) {
    return(
    <Routes>
      {AUTH_ROUTES?.map((r, i) => {
        return <Route path={r?.route} element={r?.component} key={i} />;
      })}
      <Route path="*" element={<Navigate to={"/auth"} />} />
    </Routes>
    )
  }

  return (
    <AppLayout>
      <Routes>
        {ROUTES?.map((r, i) => {
          return <Route path={r?.route} element={r?.component} key={i} />;
        })}
        <Route path="*" element={<Navigate to={"/dashboard"} />} />
      </Routes>
    </AppLayout>
  );
}

export default App;
