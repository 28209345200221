import { Button, Card, Modal, Table } from 'antd'
import React, { useState } from 'react'
import { getCardTitle } from '../../helper/helperComp'
import {  IoMdAddCircle } from 'react-icons/io'
import { useSelector } from 'react-redux'
import VendorEditor from './VendorEditor'
import { useNavigate } from 'react-router-dom'

function Vendors() {
    const {user,Vendors}=useSelector(s=>s.userreducer)
    const [open,setOpen]=useState(false)
    const navigate = useNavigate()


    const columns=[
        {title:"CompanyName", dataIndex:"company_name",key:"company_name"},
        {title:"Name", dataIndex:"name",key:"name"},
        {title:"Mobile", dataIndex:"mobile",key:"mobile"},
        {title:"Actions", dataIndex:"action",key:"action"},
    ]

  return (
  <>
  <Modal open={open} onCancel={()=>setOpen(false)} title="Vendor" footer={null}>
    <VendorEditor onSuccess={()=>setOpen(false)}/>
  </Modal>
  <Card title={getCardTitle("Vendors",<IoMdAddCircle size={25} onClick={()=>setOpen(true)} className='cursor-pointer'/>)}>
    <div className='overflow-x-auto'>
<Table columns={columns} dataSource={Vendors?.map((v)=>{
    return {
        ...v,
        action:<Button onClick={()=>navigate(`/bookings?vendorId=${v?._id}`)}>View Bookings</Button>
    }
})}/>
    </div>
  </Card>
  </>
  )
}

export default Vendors