import { GET_DRIVERS } from "../types/drivers.types"
import { get } from "../../utilities/apis"




export const GetDrivers=(vendorId)=>{
    return async(dispatch)=>{
        const res = await get(`/get-drivers?vendorId=${vendorId||""}`)

        if(res?.success){
            dispatch({
                type:GET_DRIVERS,
                payload:res?.drivers
            })
        }

    }
}