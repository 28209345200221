import { Typography } from "antd";
import React from "react";

function DataCard({ label, data, subLabel }) {
  return (
    <div className="flex flex-col gap-3 justify-center items-center bg-SECONDARY rounded-lg py-7  shadow-md">
      <Typography.Text className="font-semibold text-white text-3xl">
        {data}
      </Typography.Text>
      <Typography.Text className="text-slate-100">{label}</Typography.Text>
    </div>
  );
}

export default DataCard;
