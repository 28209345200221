import { MdDashboard } from "react-icons/md";
import { CgPlayListSearch } from "react-icons/cg";
import { FaUsersLine } from "react-icons/fa6";
import { IoCarSport } from "react-icons/io5";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/auth/Login";
import Bookings from "./pages/bookings/Bookings";
import BookingOverview from "./pages/bookings/BookingOverview";
import Vendors from "./pages/vendors/Vendors";
import Cars from "./pages/cars/Cars";
import CarEditor from "./pages/cars/CarEditor";
import Drivers from "./pages/drivers/Drivers";
import { HiMiniUsers } from "react-icons/hi2";
import CarModalEditor from "./pages/cars/CarModalEditor";
import DriverEditor from "./pages/drivers/DriverEditor";
import { HiUsers } from "react-icons/hi2";
import Users from "./pages/users/Users";
import UserEditor from "./pages/users/UserEditor";
import UserOverview from "./pages/users/UserOverview";
import { LuMenuSquare } from "react-icons/lu";
import Bills from "./pages/bills/Bills";
import BillEditor from "./pages/bills/BillEditor";
export const ROUTES = [
  {
    name: "Dashboard",
    key: "dashboard",
    role: ["admin", "vendor"],
    show: true,
    icon: <MdDashboard size={25} />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    name: "Users",
    key: "users",
    role: ["admin"],
    show: true,
    icon: <HiUsers size={25} />,
    route: "/users",
    component: <Users />,
  },
  {
    name: "Users",
    key: "users",
    role: ["admin"],
    show: false,
    icon: <HiUsers size={25} />,
    route: "/users/view/:userId",
    component: <UserOverview />,
  },
  {
    name: "Users",
    key: "users",
    role: ["admin"],
    show: false,
    icon: <HiUsers size={25} />,
    route: "/users/:action",
    component: <UserEditor />,
  },
  {
    name: "Bookings",
    key: "bookings",
    role: ["admin", "vendor"],
    show: true,
    icon: <CgPlayListSearch size={25} />,
    route: "/bookings",
    component: <Bookings />,
  },
  {
    name: "Booking",
    key: "booking",
    role: ["admin", "vendor"],
    show: false,
    icon: <CgPlayListSearch size={25} />,
    route: "/bookings/booking/:type",
    component: <BookingOverview />,
  },

  {
    name: "Bills",
    key: "bills",
    role: ["admin", "vendor"],
    show: true,
    icon: <LuMenuSquare size={25} />,
    route: "/bills",
    component: <Bills />,
  },
  {
    name: "Bills",
    key: "bills",
    role: ["admin", "vendor"],
    show: false,
    icon: <LuMenuSquare size={25} />,
    route: "/bills/:billId",
    component: <BillEditor />,
  },
  //vendor
  {
    name: "Vendors",
    key: "vendors",
    role: ["admin"],
    show: true,
    icon: <FaUsersLine size={25} />,
    route: "/vendors",
    component: <Vendors />,
  },
  {
    name: "Cars",
    key: "cars",
    role: ["admin","vendor"],
    show: true,
    icon: <IoCarSport size={25} />,
    route: "/cars",
    component: <Cars />,
  },
  {
    name: "Cars",
    key: "cars",
    role: ["admin","vendor"],
    show: false,
    icon: <IoCarSport size={25} />,
    route: "/cars/:action",
    component: <CarEditor />,
  },
  {
    name: "Cars",
    key: "cars",
    role: ["admin","vendor"],
    show: false,
    icon: <IoCarSport size={25} />,
    route: "/cars/modal/:action",
    component: <CarModalEditor />,
  },
  {
    name: "Drivers",
    key: "drivers",
    role: ["admin","vendor"],
    show: true,
    icon: <HiMiniUsers size={25} />,
    route: "/drivers",
    component: <Drivers />,
  },
  {
    name: "Drivers",
    key: "drivers",
    role: ["admin","vendor"],
    show: false,
    icon: <HiMiniUsers size={25} />,
    route: "/drivers/:action",
    component: <DriverEditor />,
  },
];
export const AUTH_ROUTES = [
  {
    name: "Auth",
    key: "auth",
    role: ["admin", "vendor"],
    show: false,
    icon: <MdDashboard />,
    route: "/auth",
    component: <Login />,
  },
];
