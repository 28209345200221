import { get } from "../../utilities/apis"
import { GET_CARS_MODALS } from "../types/cars.types"





export const getCarsModal=()=>{
    return async(dispatch)=>{
        const res = await get("/get-cars-modals")

        if(res?.success){
            dispatch({
                type:GET_CARS_MODALS,
                payload:res?.cars
            })
        }
    }
}