"use client"
import { combineReducers } from "redux";
import bookingreducer from "./booking.reducer";
import { userreducer } from "./user.reducer";
import { carsreducer } from "./cars.reducer";
import { driverreducer } from "./drivers.reducer";
import { dashboardReducer } from "./dashboard.reducer";


 const rootReducer = combineReducers({
 bookingreducer:bookingreducer,
 userreducer,
 carsreducer,
 driverreducer,
 dashboardReducer

})


export default rootReducer