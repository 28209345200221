import { Button, Typography } from "antd";
import React, { useContext } from "react";
import { ROUTES } from "../Routes";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AppContext from "../context/context";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

function SideNav() {
  const { pathname } = useLocation();
  const parent = pathname?.split("/")?.at(1);
  const { user } = useSelector((s) => s.userreducer);
  const { sideBar, setSideBar } = useContext(AppContext);

  return (
    <div className="w-full h-full bg-gray-900 p-2 rounded-xl shadow flex flex-col gap-4 relative transition-all">
      <div
        className={`flex items-center ${
          sideBar ? " gap-2" : "justify-center"
        } `}
      >
        <div className="w-[70px] h-70px overflow-hidden transition-all">
          <img
            src={require("../assests/favicon.png")}
            alt="logo"
            className="w-full object-cover h-full rounded-full transition-all"
          />
        </div>

        {/* <div className={`flex flex-col px-4 items-center ${sideBar?"flex":"lg:hidden"}`}>
        <Typography.Text className="text-primary text-lg  text-white">{user?.name}</Typography.Text>
        <Typography.Text className="text-white text-xs">{user?.role}</Typography.Text>
      </div> */}
      </div>

      <div className="flex flex-col gap-3 mt-4">
        {ROUTES.map((r, i) => {
          if (r?.show) {
            return (
              <Link
                to={r?.route}
                className={`${
                  parent === r?.key
                    ? "bg-PRIMARY text-white shadow-lg"
                    : "bg-slate-700 hover:bg-PRIMARY text-white"
                } transition-all p-4 rounded-md flex items-center gap-3 ${
                  sideBar ? "" : "justify-center"
                }`}
              >
                {r?.icon && r?.icon}
                <b className={`${sideBar ? "" : "lg:hidden"}`}>{r?.name}</b>
              </Link>
            );
          }
        })}
      </div>

      <div
        className={`absolute bottom-5 right-5 text-white ${
          sideBar ? "" : "rotate-180"
        } transition-all`}
        onClick={() => setSideBar(!sideBar)}
      >
        <FaRegArrowAltCircleLeft size={30} />
      </div>
    </div>
  );
}

export default SideNav;
