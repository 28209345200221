import { Button, Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { post } from '../../utilities/apis'
import { getVendors } from '../../redux/actions/user.actions'

function VendorEditor({onSuccess}) {
    const [form]=useForm()
    const [loading,setLoading]=useState(false)
    const dispatch = useDispatch()

    const onFinish = async (vals) => {
        setLoading(true)
        const res = await post("/auth/register", vals);
    
        if (res?.success) {
          message.success("Vendor added successfully!")
          // setValues(initVal);
          form.resetFields()
          dispatch(getVendors())
          if(onSuccess){
            onSuccess()
          }
         
        }else{
            message.error(res?.message)
        }
        setLoading(false)
      };
  return (
    <div>
        <Form layout='vertical' form={form} onFinish={onFinish}>

     <Form.Item label="CompanyName" name={"company_name"}>
        <Input/>
     </Form.Item>
     <Form.Item label="Owner Name" name={"name"}>
              <Input/>

            </Form.Item>
            <Form.Item label="Mobile" name={"mobile"}>
              <Input />

            </Form.Item>
            <Form.Item label="Email" name={"email"}>
              <Input />

            </Form.Item>
            <Form.Item label="Password" name={"password"}>
              <Input />

            </Form.Item>


            <Button type='primary' htmlType='submit' loading={loading}>Submit</Button>


        </Form>

    </div>
  )
}

export default VendorEditor