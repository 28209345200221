import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../utilities/apis";
import { useForm } from "antd/es/form/Form";
import { Button, Card, Form, Input, Select, message } from "antd";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import { getCardTitle } from "../../helper/helperComp";
import { BILLSTATUS } from "../../helper/constants";

function BillEditor() {
  const { billId } = useParams();
  const [bill, setBill] = useState();
  const [updating, setUpdating] = useState(false);

  const [form] = useForm();
  const navigate = useNavigate()
  const getBill = async () => {
    const res = await get("/bills", { billId });
    if (res?.success) {
      setBill(res?.bill);
      const b = res?.bill;
      form.setFieldsValue({
        ...b,
        to: b?.to?.name,
      });
    }
  };


  const onSubmit=async(values)=>{
    setUpdating(true)
    const res = await put(`/bills/update-bill/${billId}`,values)

    if(res?.success){
        message.success(res?.message)
        navigate(-1)
    }else{
      message.error(res?.message)
    }
    
    setUpdating(false)

  }

  useEffect(() => {
    getBill();
  }, []);

  return (
    <Card title={getCardTitle("Bill","",bill?.billId)} loading={!bill}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item name={"to"} label="Bill To">
          <Input />
        </Form.Item>

        <Form.Item label={"Bill Info"}>
          <Form.List name="data">
            {(fields, { add, remove }) => (
              <div className="w-full">
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div key={key} className="gap-2 flex  w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing first name",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Label" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing last name",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Value" />
                      </Form.Item>

                      <CiCircleRemove onClick={() => remove(name)} size={25} />
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<IoIosAdd />}
                  >
                    Add Data
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>

        <div className="grid grid-cols-3 gap-2">
          <Form.Item name={"total_amount"} label="Total">
            <Input />
          </Form.Item>
          <Form.Item name={"paid_amount"} label="Paid">
            <Input />
          </Form.Item>
          <Form.Item name={"pending_amount"} label="Pending">
            <Input />
          </Form.Item>
        </div>

        <Form.Item name={"status"} label="Status">
            <Select options={Object.entries(BILLSTATUS)?.map(([key,value])=>{
                return {
                    label:key,
                    value:value
                }
            })} />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={updating}>Submit</Button>
      </Form>
    </Card>
  );
}

export default BillEditor;
