


export const vaildObjData = (obj)=>{
 
    let vaild={}
    Object.keys(obj).map((o)=>{
        if(obj[o]!="undefined"){
            vaild[o]=obj[o]
        }
    })
    return vaild
}


export const getRandomColor=()=> {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }