import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { getCardTitle } from "../../helper/helperComp";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { get, post, put } from "../../utilities/apis";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { BookingPaymentStatus, BookingStatus, DefaultDateFormat } from "../../helper/constants";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosAdd, IoMdShare } from "react-icons/io";
import { useSelector } from "react-redux";
import { usePDF } from "react-to-pdf";
import { vaildObjData } from "../../helper/helper";

const filterOptions = (input, option) => {
  return (option?.label2?.toLowerCase() ?? "")?.includes(input?.toLowerCase());
};

function BookingOverview() {
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
  const { CarsModals } = useSelector((s) => s.carsreducer);
  const { user, Vendors } = useSelector((s) => s.userreducer);
  const { type } = useParams();
  const [form] = useForm();
  const [booking, setBooking] = useState();
  const [fetching, setFetching] = useState(false);
  const [changingValues, setChangingValues] = useState();
  const value = (key) => form.getFieldValue(key);
  const setValue = (key, val) => form.setFieldValue(key, val);
  const [searchPramas, setSearchParams] = useSearchParams();
  const bookingId = searchPramas.get("bookingId");
  const userId = searchPramas.get("userId");
  const actionType = searchPramas.get("actionType");
  const [drivers, setDrivers] = useState();
  const [cars, setCars] = useState();
  const navigate = useNavigate()

  const getBooking = async () => {
    setFetching(true);
    const res = await get(`/get-booking?bookingId=${bookingId}`);
    if (res?.success) {
      setBooking(res?.booking);
    }
    setFetching(false);
  };

  const onSubmit = async (val) => {
    let vals = vaildObjData(val);
 
    // Object.keys(val)?.map((v) => {
    //   if (val[v]) {
    //     vals[v] = val[v];
    //   }
    // });
    if(userId){
      vals.user= userId
    }
    if (vals?.pick_up_date) {
      vals.pick_up_date = dayjs(vals.pick_up_date).format(DefaultDateFormat);
    }
    if (vals?.return_date) {
      vals.return_date = dayjs(vals.return_date).format(DefaultDateFormat);
    }
    

    const reqBody = vals;
    if (type === "edit") {
      const update = await put(`/update-booking/${booking?._id}`, reqBody);
      if (update?.success) {
        message.success(update?.message);
        navigate(-1)
      }else{
        message.error(update.message)
      }
      return;
    }
    if (type === "new") {
      const create = await post(`/create-booking`, reqBody);
      if (create?.success) {
        message.success("Booking Created Successfully!");
        form.resetFields();
        navigate(-1)
      }
      return;
    }
  };

  const key = "updatable";
  const getShareLink = async () => {
    message.open({
      key,
      type: "loading",
      content: "Generating Link..",
    });

    const res = await get(`/share-booking/${bookingId}`);
    if (res?.success) {
      await navigator.clipboard.writeText(res?.Link);
      message.open({
        key,
        type: "success",
        content: "Link Copied To Clipboard",
      });
    } else {
      message.open({
        key,
        type: "error",
        content: res?.message,
      });
    }
  };



  const getCars = async () => {
 
    setCars(null);
    const res = await get(
      `/get-cars?modal_id=${form.getFieldValue(
        "car_modal"
      )}&vendor_id=${value("vendor")}`
    );
    if (res?.success) {
      // form.setFieldValue("car","")
      setCars(
        [{label:"Select Car..",value:undefined},...res?.cars?.map((c) => {
          return {
            label2: c?.name + " " + c?.car_number,
            label: (
              <Typography.Text className="flex gap-1">
                {c?.name}
             
                <Tag color={"cyan"}>
                  {c?.car_number}
                </Tag>
                <Tag color={c?.availability ? "success" : "error"}>
                  {c?.availability ? "Yes" : "No"}
                </Tag>
              </Typography.Text>
            ),
            value: c?._id,
          };
        })]
      );
    }
  };
  const getDrivers = async () => {
    setCars(null);
    const res = await get(
      `/get-drivers`
    );
    if (res?.success) {
      // form.setFieldValue("car","")
      setDrivers(
        res?.drivers?.map((c) => {
          return {
            label2: c?.name + " " + c?.mobile,
            label: (
              <Typography.Text>
                {c?.name}
                <Tag color={c?.availability ? "success" : "error"}>
                  {c?.availability ? "Yes" : "No"}
                </Tag>
              </Typography.Text>
            ),
            value: c?._id,
          };
        })
      );
    }
  };

  useEffect(() => {
    
    if (changingValues) {
      if("km_amount" in changingValues||"extra_fare_total" in changingValues){
    
        setValue(
          "final_amount",
          parseInt(
            Number(value("km_amount")) + Number(value("extra_fare_total"))
          )
        );
      }
      if("km_diffrence" in changingValues){
        setValue(
          "km_amount",
          Number(value("km_diffrence")) * Number(value("km_rate"))
        );

        setValue(
          "final_amount",
          parseInt(
            Number(value("km_amount")) + Number(value("extra_fare_total"))
          )
        );
      }
      if (
        "start_km" in changingValues ||
        "end_km" in changingValues ||
        "km_rate" in changingValues||
        "km_amount" in changingValues
      ) {
        if (value("start_km") && value("end_km")) {

          setValue(
            "km_diffrence",
            Number(value("end_km")) - Number(value("start_km"))
          );

          setValue(
            "km_amount",
            Number(value("km_diffrence")) * Number(value("km_rate"))
          );

          setValue(
            "final_amount",
            parseInt(
              Number(value("km_amount")) + Number(value("extra_fare_total"))
            )
          );
        } else if(value("km_amount")){
         
          setValue(
            "final_amount",
            parseInt(
              Number(value("km_amount")) + Number(value("extra_fare_total"))
            )
          );
        
        }else{
          setValue("km_diffrence", 0);
        }
      }
      
      if ("extra_fares" in changingValues) {
        const sum =
          value("extra_fares")?.length > 0 &&
          value("extra_fares").reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue?.value),
            0
          );

          form.setFieldValue("extra_fare_total", sum||0);
          setValue(
            "final_amount",
            Number(
              Number(value("km_amount")) + Number(value("extra_fare_total"))
            )||0
          )
        
      }
      if("vendor" in changingValues||"car_modal" in changingValues){
        if (CarsModals) {
          const md = CarsModals?.find(
            (cm) => cm?._id === value("car_modal")
          );
          setValue("km_rate", md?.our_price);
          setValue("vendor_km_rate", md?.vendor_price);
        }
          setValue("car",undefined)
          getCars()
      }
    }
  }, [changingValues]);

  useEffect(() => {
    if (booking && !fetching) {
      if (booking?.start_km && booking?.end_km) {
        setValue(
          "km_diffrence",
          Number(Number(booking?.end_km) - Number(booking?.start_km))
        );
        setValue(
          "km_amount",
          Number(Number(booking?.end_km) - Number(booking?.start_km)) *
            Number(booking?.km_rate)
        );
        setValue(
          "final_amount",
          parseInt(
            Number(value("km_amount"))||0 + Number(value("extra_fare_total")||0)
          )
        );
      }

      if (booking?.extra_fares?.length > 0) {
        const sum = booking?.extra_fares?.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue?.value)||0,
          0
        );
   
          form.setFieldValue("extra_fare_total", sum||0);
        
      }
      if(booking?.vendor||booking?.car_modal){
       setValue("vendor",booking?.vendor);
       setValue("car_modal",booking?.car_modal);
       setValue("car",booking?.car);
        getCars()
      }
    }
  }, [fetching, booking]);

  useEffect(() => {
    getDrivers()
  
    if (bookingId) {
      getBooking();
    }
  }, [bookingId]);

  useEffect(() => {
    if (booking) {
      form.setFieldsValue({
        ...booking,
        pick_up_date: dayjs(booking?.pick_up_date, DefaultDateFormat),
        ...(booking?.return_date && {
          return_date: dayjs(booking?.return_date, DefaultDateFormat),
        }),
      });
    }
  }, [booking]);

  return (
    <Card
      title={getCardTitle(
        "Booking",
        booking&&<IoMdShare
          size={20}
          className="cursor-pointer"
          onClick={getShareLink}
        />,
        type==="edit"?booking?.booking_id||"":""
      )}
      loading={fetching}
    >
    

   
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onValuesChange={(e) => setChangingValues(e)}
 
      >
        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>
        <div className=" grid grid-cols-2 gap-2">
          <Form.Item label="Mobile" name={"mobile"}>
            <Input />
          </Form.Item>
  
          <Form.Item label="Email" name={"email"}>
            <Input />
          </Form.Item>
        </div>
        <div className=" grid grid-cols-3 gap-2">
          <Form.Item label="Pick Up " name={"pick_up"}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Pick Up Date"
            name={"pick_up_date"}
            className="w-full"
          >
            <DatePicker format={DefaultDateFormat} className="w-full" />
          </Form.Item>
          <Form.Item label="Pick Up Time" name={"pick_up_time"}>
            <Input />
          </Form.Item>
        </div>

        <div className=" grid grid-cols-3 gap-2">
          <Form.Item label="Destination " name={"drop_off"}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Return Date"
            name={"return_date"}
            className="w-full"
          >
            <DatePicker format={DefaultDateFormat} className="w-full" />
          </Form.Item>
          <Form.Item label="Return Time" name={"return_time"}>
            <Input />
          </Form.Item>
        </div>
        <Form.Item name="passengers" className="w-full" label={"Passengers"}>
          <Input />
        </Form.Item>

        <Form.Item label={"Address Info"}>
          <Form.List name="address_info">
            {(fields, { add, remove }) => (
              <div className="w-full">
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div key={key} className="gap-2 flex  w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing first name",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Label" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing last name",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Value" />
                      </Form.Item>

                      <CiCircleRemove onClick={() => remove(name)} size={25} />
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<IoIosAdd />}
                  >
                    Add Address
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>

        {user?.role === "admin" && (
          <Form.Item name="vendor" vendor="w-full" label={"Vendor"}>
            <Select
              options={Vendors?.map((o) => {
                return {
                  label: o?.company_name + ` (${o?.name})`,
                  label2: o?.company_name + ` (${o?.name})`,
                  value: o?._id,
                };
              })}
              showSearch
              filterOption={filterOptions}
            />
          </Form.Item>
        )}
       <div className="flex items-center gap-2 w-full">
                  <Form.Item
                    name="car_modal"
                    className="w-full"
                    label={"Car Modal"}
                  >
                    <Select
                      options={CarsModals?.map((o) => {
                        return {
                          label: o?.name,
                          value: o?._id,
                        };
                      })}
                      showSearch
                      filterOption={filterOptions}
                    />
                  </Form.Item>
                  <Form.Item
                    name="car"
                    className="w-full"
                    label={"Car"}
                  >
                    <Select
                      options={cars}
                      // disabled={!cars}
                      loading={!cars}
                      showSearch
                      filterOption={filterOptions}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="driver"
                  vendor="w-full"
                  label={"Driver"}
                >
                  <Select
                    options={drivers || []}
                    showSearch
                    filterOption={filterOptions}
                  />
                </Form.Item>
        <div>
          <Typography.Text>Km Informatin</Typography.Text>
          <div className=" grid grid-cols-3 gap-2 items-center">
            <Form.Item name="km_rate" label={"Per Km Rate"}>
              <Input />
            </Form.Item>
            <Form.Item name="vendor_km_rate" label={"Vendor Km Rate"}>
              <Input />
            </Form.Item>
            <Form.Item name="start_km" label={"Starting Km"}>
              <Input />
            </Form.Item>
            <Form.Item name="end_km" label={"Ending Km"}>
              <Input />
            </Form.Item>
            <Form.Item name="km_diffrence" label={"Diffrence(km)"}>
              <Input />
            </Form.Item>
          </div>
        </div>

        <Form.Item label={"Extra Charges"}>
          <Form.List name="extra_fares">
            {(fields, { add, remove }) => (
              <div className="w-full">
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div key={key} className="gap-2 flex  w-full">
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Label",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Label" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Value ",
                          },
                        ]}
                        className="flex-1"
                      >
                        <Input placeholder="Value" />
                      </Form.Item>

                      <CiCircleRemove onClick={() => remove(name)} size={25} />
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<IoIosAdd />}
                  >
                    Add Charge
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>

        <div>
          <Typography.Text>Bill Details</Typography.Text>

          <div className=" grid grid-cols-3 gap-2 items-center">
            <Form.Item name="km_amount" label={"Km Fare"}>
              <Input  />
            </Form.Item>
            <Form.Item name="extra_fare_total" label={"Etra Fares Total"}>
              <Input  />
            </Form.Item>
            <Form.Item name="total_paid_amount" label={"User Paid Amount"}>
              <Input />
            </Form.Item>
            <Form.Item name="final_amount" label={"Final Amount"}>
              <Input  />
            </Form.Item>
          </div>
        </div>
        <div>
          <Typography.Text>Expense Details</Typography.Text>

          <div className=" grid grid-cols-3 gap-2 items-center">
            <Form.Item name="driver_price" label={"Paid To Driver"}>
              <Input  />
            </Form.Item>
        
            <Form.Item name="total_expenses" label={"Total Expenses"}>
              <Input  />
            </Form.Item>
          
          </div>
        </div>

        <Form.Item name="user_payment_status" className="w-full" label={"User payment Status"}>
          <Select
            options={Object.entries(BookingPaymentStatus)?.map(([key, value]) => {
              return {
                label: key,
                value: value,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="vendor_payment_status" className="w-full" label={"Vendor payment Status"}>
          <Select
            options={Object.entries(BookingPaymentStatus)?.map(([key, value]) => {
              return {
                label: key,
                value: value,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="status" className="w-full" label={"Status"}>
          <Select
            options={Object.entries(BookingStatus)?.map(([key, value]) => {
              return {
                label: key,
                value: value,
              };
            })}
          />
        </Form.Item>

        <Button htmlType="submit">Submit</Button>
      </Form>

    </Card>
  );
}

export default BookingOverview;
