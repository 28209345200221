import { Button, Card, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { post } from "../../utilities/apis";
import { useNavigate } from "react-router-dom";
import { GETUSER } from "../../redux/types/user.types";

function Login() {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (vals) => {
    setLoading(true);
    const res = await post("/auth/login", vals);
    if (res?.success) {
      dispatch({
        type: GETUSER,
        payload: res?.user,
      });
      message.success(res?.message)
      localStorage.setItem("JWT_TOKEN_ADMIN",res?.token)
      navigate("/dashboard");
    }else{
      message.error(res?.message)
    }
    setLoading(false)
  };
  return (
    <div className="w-full h-screen flex  justify-center">
      <Card
        className="h-fit mt-[120px] w-full max-w-[400px]"
        title="Login Here"
      >
        <Form layout="vertical" onFinish={onSubmit} form={form}>
          <Form.Item label="Email" name={"email"}>
            <Input/>

          </Form.Item>
          <Form.Item label="Password" name={"password"} >
            <Input type="password"/>
          </Form.Item>
          <Button loading={loading} type="primary" className="w-full" htmlType="submit">Login</Button>
        </Form>
      </Card>
    </div>
  );
}

export default Login;
