import { Button, Card, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getCardTitle } from "../../helper/helperComp";
import { BILLSTATUS, BookingStatus, DefaultDateFormat } from "../../helper/constants";
import { get } from "../../utilities/apis";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import dayjs from "dayjs";

const tabList = Object.keys(BILLSTATUS)?.map((bs) => {
  return {
    label: <Typography.Text className="capitalize">{bs}</Typography.Text>,
    key: BILLSTATUS[bs],
  };
});

function Bills(props) {
  const { user } = useSelector((s) => s.userreducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId") || props.user;
  const vendorId = searchParams.get("vendorId");
  const [status, setStatus] = useState(BILLSTATUS.PENDING);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const columns = [
    { title: "Bill Id", dataIndex: "billId", key: "billId" },
    { title: "To", dataIndex: "to", key: "to" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Total", dataIndex: "total_amount", key: "total_amount" },
    { title: "Paid", dataIndex: "paid_amount", key: "paid_amount" },
    { title: "Pending", dataIndex: "pending_amount", key: "pending_amount" },
    { title: "Status", dataIndex: "status", key: "status" },
 
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  const getData = async () => {
    setLoading(true);

    const query = {
      ...(userId ? { userId } : {}),
      ...(status ? { status } : {}),

    };

    const res = await get(`/bills`, query);

    if (res?.success) {
      setTotal(res?.count);
      setData(
        res?.bills?.map((b) => {
          const pp = Number(b?.final_amount) - Number(b?.total_paid_amount);
          return {
            ...b,
            to:<Link to={`/users/view/${b?.to?._id}`}>{b?.to?.name}</Link>,
            date:dayjs(b?.createdAt).format(DefaultDateFormat),
            action: <Link to={`/bills/${b?._id}`}><Button>Edit</Button></Link>,
          };
        })
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [status, page, limit]);

  return (
    <div>
      <Card
        title={getCardTitle(
          "Bills",
        
        )}
        tabList={tabList}
        activeTabKey={status}
        onTabChange={(t) => setStatus(t)}
      >
        <div className="overflow-x-auto">
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={{
              pageSize: limit,
              onChange: (e) => setPage(e),
              total: total,
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Bills;
