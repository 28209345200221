import { GET_DRIVERS } from "../types/drivers.types";




export const driverreducer =(state={},action)=>{
    const{type,payload}=action;

    switch (type) {
        case GET_DRIVERS:
           return {...state,Drivers:payload}
    
        default:
            return state
    }
}