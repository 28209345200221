import { Spin } from 'antd'
import React from 'react'

function Loader() {
  return (
    <div className='w-full h-full flex items-center justify-center'>
<Spin/>
    </div>
  )
}

export default Loader