import { Typography } from "antd"



export const getCardTitle =(text,icon,helpText)=>{
    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-col ">
            <Typography.Text className="text-lg capitalize">{text}</Typography.Text>
            {helpText&&<Typography.Text type="secondary">{helpText}</Typography.Text>}

            </div>
            {icon&&icon}
        </div>
    )
}